import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { getToken, validateMeeting } from "../api";
import { USER_TYPES } from "../config/api";
import { SocketContext, SocketProvider } from "../socket/socketContext";
import { getItem, setItem } from "../utils/localStorageControl";
import { data } from "autoprefixer";
import { message } from "antd";
import ClosedMeeting from "./screens/ClosedMeeting";

export function MeetingDetailsScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  videoTrack,
  setVideoTrack,
  onClickStartMeeting,
}) {
  const [meetingId, setMeetingId] = useState("");
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(true);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(false);
  const [statusHook, setStatusHook] = useState("");
  const [Joineevalidate, setJoineevalidate] = useState(true);
  const [disabledBtn, setDisableBtn] = useState(true);
  //XPO_FAIRS hook
  const socket = useContext(SocketContext);
  const verifiedResponse = getItem("verifiedResponse");
  const meetingResume = getItem("meetingResume");

  const personRole = getItem("personRole");

  useEffect(() => {
    socket.on("startScheduledMeetingResponse", (data) => {
      message.info("Now You Can Join The Meeting");

      setStatusHook(data.status);
    });

    socket.on("joinScheduledMeetingResponse", (data) => {
      console.log(
        "🚀 ~ file: MeetingDetailsScreen.js:41 ~ socket.on ~ data:",
        data
      );
      // setStatusHook("start");

      setJoineevalidate(data.flag);
      setDisableBtn(false);
    });
  }, [socket]);

  useMemo(() => {
    if (personRole === USER_TYPES.VISITOR) {
      setDisableBtn(false);
      socket.emit("openChat", {
        senderId: verifiedResponse.creatorId,
      });
    }
    if (personRole === USER_TYPES.EXHIBITOR) {
      setDisableBtn(false);
      socket.emit("openChat", {
        senderId: verifiedResponse.joineeId,
      });
    }
    console.log("sssssssssssssssssssssss", verifiedResponse.status);
    setStatusHook(
      verifiedResponse.status === "pending" ? "start" : verifiedResponse.status
    );
    setMeetingId(verifiedResponse.meetingId);
    setParticipantName(
      `${
        personRole === USER_TYPES.EXHIBITOR
          ? verifiedResponse.joinee.firstName +
            " " +
            verifiedResponse.joinee.lastName
          : verifiedResponse.creator.firstName +
            " " +
            verifiedResponse.creator.lastName
      }`
    );
    if (verifiedResponse.status === "start") {
      if (!meetingResume) {
        console.log("---80-----");
        setDisableBtn(true);
        socket.emit("joinScheduledMeeting", {
          userId: verifiedResponse.creatorId,
          meetingId: verifiedResponse.meetingId,
        });
      }
    }
  }, []);

  if (statusHook === "closed") {
    return <ClosedMeeting />;
  } else {
    return (
      <div
        className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}
      >
        {iscreateMeetingClicked ? (
          <div className="border border-solid border-gray-400 rounded-xl px-4 py-3  flex items-center justify-center">
            <p className="text-white text-base">
              {`Meeting code : ${meetingId}`}
            </p>
            <button
              className="ml-2"
              onClick={() => {
                navigator.clipboard.writeText(meetingId);
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 3000);
              }}
            >
              {isCopied ? (
                <CheckIcon className="h-5 w-5 text-green-400" />
              ) : (
                <ClipboardIcon className="h-5 w-5 text-white" />
              )}
            </button>
          </div>
        ) : isJoinMeetingClicked ? (
          <>
            <input
              defaultValue={meetingId}
              onChange={(e) => {
                setMeetingId(e.target.value);
              }}
              placeholder={"Enter meeting Id"}
              className="px-4 py-3 bg-gray-650 rounded-xl text-white w-full text-center"
            />
            {meetingIdError && (
              <p className="text-xs text-red-600">{`Please enter valid meetingId`}</p>
            )}
          </>
        ) : null}

        {(iscreateMeetingClicked || isJoinMeetingClicked) && (
          <>
            <input
              value={participantName}
              onChange={(e) => setParticipantName(e.target.value)}
              placeholder="Enter your name"
              className="px-4 py-3 mt-5 bg-gray-650 rounded-xl text-white w-full text-center"
            />{" "}
            {/* <p className="text-xs text-white mt-1 text-center">
            Your name will help everyone identify you in the meeting.
          </p> */}
            <button
              disabled={disabledBtn}
              className={`w-full ${
                participantName.length < 3 ? "bg-gray-650" : "bg-purple-350"
              }  text-white px-2 py-3 rounded-xl mt-5`}
              onClick={async (e) => {
                if (iscreateMeetingClicked) {
                  if (!Joineevalidate) {
                    message.info("Not allowed.....");
                    return;
                  }
                  if (
                    personRole === USER_TYPES.VISITOR &&
                    statusHook !== "start"
                  ) {
                    message.info(
                      "Please wait... Your exhibitor will start meeting soon"
                    );
                    return;
                  }
                  if (personRole === USER_TYPES.EXHIBITOR) {
                    if (videoTrack) {
                      videoTrack.stop();
                      setVideoTrack(null);
                    }
                    socket.emit("startScheduledMeeting", verifiedResponse);
                    setItem("meetingStatus", "start");
                    setStatusHook("start");
                    const token = await getToken();
                    setItem(
                      "ParticipantDisplayName",
                      participantName +
                        "?" +
                        verifiedResponse.joinee.profilePicture
                    );
                    setParticipantName(
                      participantName +
                        "?" +
                        verifiedResponse.joinee.profilePicture
                    );
                    onClickStartMeeting(token, meetingId);
                  } else {
                    if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                      socket.emit("joinScheduledMeeting", {
                        userId: verifiedResponse.creatorId,
                        meetingId: verifiedResponse.meetingId,
                      });
                      setItem(
                        "ParticipantDisplayName",
                        participantName +
                          "?" +
                          verifiedResponse.creator.profilePicture
                      );
                      setParticipantName(
                        participantName +
                          "?" +
                          verifiedResponse.creator.profilePicture
                      );
                      onClickJoin(meetingId);
                      setItem("meetingStatus", "start");
                    } else setMeetingIdError(true);
                  }
                }
              }}
            >
              {iscreateMeetingClicked && personRole === USER_TYPES.EXHIBITOR
                ? "Start a meeting"
                : "Join a meeting"}
            </button>
          </>
        )}
      </div>
    );
  }
}
